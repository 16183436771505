$feature-flags: (
  ui-shell: true,
  grid-columns-16: true,
  enable-css-custom-properties: true,
);

// Feature flags
$css--font-face: true;
$css--plex: true;

// Global styles
@import 'carbon-components/scss/globals/scss/css--font-face';
@import 'carbon-components/scss/globals/grid/grid';
@import 'carbon-components/scss/globals/scss/styles.scss';

// Carbon components
@import 'carbon-components/scss/components/breadcrumb/breadcrumb';
@import 'carbon-components/scss/components/button/button';
@import 'carbon-components/scss/components/data-table/data-table';
@import 'carbon-components/scss/components/link/link';
@import 'carbon-components/scss/components/pagination/pagination';
@import 'carbon-components/scss/components/tabs/tabs';
@import 'carbon-components/scss/components/ui-shell/ui-shell';

$carbon--theme: $carbon--theme--g10;

@include carbon--theme();
/// The React <Content /> component uses the `main` element which IE11 doesn't recognize
/// @link https://github.com/carbon-design-system/carbon/issues/2789
main {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bx--tile {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 5px;
}

.rounded {
  border-radius: 10px;
}

/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .bx--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @include carbon--theme($carbon--theme--g100, true);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    @include carbon--theme($carbon--theme--g10, true);
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.bx--content {
  padding: 0;
}
